import axios from '@axios'
import qs from 'qs'
import { getUserData } from '@/auth/utils'

const { role_id } = getUserData()
export default {
  namespaced: true,
  state: {
    condition: {
      inboundNo:undefined,
      warehouseName: undefined,
      creatorName: undefined,
      inboundCreatorName: undefined,
      supplierName: undefined,
    },
    isWarehouseStaff:role_id === 9 || role_id === 10 || role_id === 21,
    isExpressStaff:role_id === 17 || role_id === 18,
    isFinanceStaff:role_id === 11 || role_id === 12,
    isSuper:role_id === 14
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    resetCondition(state){
      for (let conditionKey in state.condition) {
        state.condition[conditionKey] = undefined
      }
    }
  },
  actions: {
    exportToExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios({
            url: '/api/exportexcelfile/pibExportToExcel',
            method:'get',
            params: params,
            responseType: 'blob',
          }
        )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    warehouseSearch(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinbound/warehouseSearch', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinbound/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinbound/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    del(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinbound/del', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/purchaseinbound/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinbound/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinbound/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    toPDF(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinbound/generatePurchaseInboundTodo', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateModel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinbound/updateModel', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateModelList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinbound/updateModelList', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submitwarehouse(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinbound/submitwarehouse', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    finishinbound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinbound/finishinbound', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveAndSubmitWarehouse(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinbound/saveAndSubmitWarehouse', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    resetPurchaseInboundData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinbound/resetPurchaseInboundData', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatusSales(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinbound/changeStatusSales', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveAttachmentsWh(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinbound/saveAttachmentsWh', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
