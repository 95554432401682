<template>
  <b-card
      v-show="advanced_search_modal"
      style="width:100%"
  >
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >

        <b-row>
          <!--调拨类型        -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨类型:"
                label-for="allot_type"
                label-size="sm"
            >
              <v-select
                  id="allot_type"
                  :options="getCodeOptions('allot_type')"
                  :clearable="true"
                  v-model = "condition.allot_type"
                  class="select-size-sm"
                  placeholder="选择调拨类型"
              />
            </b-form-group>
          </b-col>
          <!--调拨状态        -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨状态:"
                label-for="status_id"
                label-size="sm"
            >
              <v-select
                  id="status_id"
                  :options="getCodeOptions('allot_status')"
                  :clearable="true"
                  v-model = "condition.status"
                  class="select-size-sm"
                  placeholder="选择调拨状态"
              />
            </b-form-group>
          </b-col>
          <!--物流审核       -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="物流审核:"
                label-for="express_check_status"
                label-size="sm"
            >
              <v-select
                  id="express_check_status"
                  :options="getCodeOptions('yesno')"
                  :clearable="true"
                  v-model = "condition.express_check_status"
                  class="select-size-sm"
                  placeholder="选择审核状态"
                  :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <!--财务审核     -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="财务审核:"
                label-for="is_finance"
                label-size="sm"
            >
              <v-select
                  id="is_finance"
                  :options="getCodeOptions('yesno')"
                  :clearable="true"
                  v-model = "condition.is_finance"
                  class="select-size-sm"
                  placeholder="选择审核状态"
                  :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <!--商品名称-->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="商品名称:"
                label-for="name"
                label-size="sm"
            >
              <b-form-input
                  v-model="condition.productName"
                  class="d-inline-block mr-1"
                  size="sm"
              />
            </b-form-group>
          </b-col>
          <!--商品69码       -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="商品69码:"
                label-for="code"
                label-size="sm"
            >
              <b-form-input
                  v-model="condition.productCode"
                  class="d-inline-block mr-1"
                  size="sm"
              />
            </b-form-group>
          </b-col>
          <!--调拨单编号      -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨单编号:"
                label-for="outbound_no"
                label-size="sm"
            >
              <b-form-input
                  v-model="condition.outboundNo"
                  class="d-inline-block mr-1"
                  size="sm"
              />
            </b-form-group>
          </b-col>

          <!--调出仓库       -->
          <b-col
              cols="12"
              md="4"
          >
            <xy-input-button
                label="调出仓库"
                type="input"
                v-on:change="fromChildren($event,['fromwarehousename','fromwarehouseId'])"
                :params="['fromwarehousename','fromwarehouseId']"
                :value="condition.fromwarehousename"
                modalName="仓库"
                placeholder="点击选择仓库"
            >
            </xy-input-button>
          </b-col>
          <!--调入仓库       -->
          <b-col
              cols="12"
              md="4"
          >
            <xy-input-button
                label="调入仓库"
                type="input"
                v-on:change="fromChildren($event,['towarehousename','towarehouseId'])"
                :params="['towarehousename','towarehouseId']"
                :value="condition.towarehousename"
                modalName="仓库"
                placeholder="点击选择仓库"
            >
            </xy-input-button>
          </b-col>

          <!--调拨时间区间    -->
          <b-col
              cols="12"
              md="4"
          >
            <xy-input-button label="调拨时间"
                             type="time"
                             v-on:change="fromChildren($event,['begin_time'])"
                             :params="['begin_time']"
                             :value="condition.begin_time"
            >
            </xy-input-button>
          </b-col>

          <!--责任人         -->
          <b-col
              cols="12"
              md="4"
          >
            <xy-input-button
                label="责任人"
                type="input"
                v-on:change="fromChildren($event,['dutyName','dutyId'])"
                :params="['dutyName','dutyId']"
                :value="condition.dutyName"
                modalName="责任人"
                placeholder="点击选择责任人"
            >
            </xy-input-button>
          </b-col>

          <!--创建时间区间   -->
          <b-col
              cols="12"
              md="4"
          >
            <xy-input-button label="创建时间"
                             type="time"
                             v-on:change="fromChildren($event,['create_begin_time'])"
                             :params="['create_begin_time']"
                             :value="condition.create_begin_time"
            >
            </xy-input-button>
          </b-col>


          <!--业务出库时间-->
          <b-col
              cols="12"
              md="4"
              style="margin-top: 15px"
          >
            <xy-input-button label="业务出库时间"
                             type="time"
                             v-on:change="fromChildren($event,['outbound_begin_time'])"
                             :params="['outbound_begin_time']"
                             :value="condition.outbound_begin_time"
            >
            </xy-input-button>
          </b-col>

          <!--业务入库时间-->
          <b-col
              cols="12"
              md="4"
              style="margin-top: 15px"
          >
            <xy-input-button label="业务入库时间"
                             type="time"
                             v-on:change="fromChildren($event,['receipt_begin_time'])"
                             :params="['receipt_begin_time']"
                             :value="condition.receipt_begin_time"
            >
            </xy-input-button>
          </b-col>

          <!--查询-->
          <b-col
              cols="12"
              md="4"
          >
            <div >
              <b-button
                  variant="outline-primary"
                  type="submit"
                  class="mr-1"
              >
                <span class="align-right">查询</span>
              </b-button>

              <b-button
                  variant="outline-primary"
                  type="reset"
                  class="mr-1"
              >
                <span class="align-right">重置</span>
              </b-button>

              <b-button
                  style="margin: 12px;"
                  variant="outline-primary"
                  @click="exportToExcel()"
              >
                <span class="align-right">导出调拨单</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
    </b-form>

  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRef, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import store from '@/store'
import ProductSelect from '@/views/apps/product/ProductSelect'
import { useToast } from 'vue-toastification/composition'
/**/
import allotoutboundStore from '@/views/apps/allotoutbound/allotoutboundStore'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import XyInputButton from "@/views/components/xy/XyInputButton";


export default {
  components: {
    WareHouseSelect,
    ProductSelect,
    UserSelect,
    CompanySelect,
    XyInputButton
  },
  props: {
    warehouseId: {
      type: Number,
      default: 0,
    },
    role:{
      type:Object,
    }
  },
  setup(props, { emit }) {

    const toast = useToast()
    // Register module
    if (!store.hasModule('allotoutbound')) store.registerModule('allotoutbound', allotoutboundStore)


    onMounted(() => {
      state.condition = store.getters['allotoutbound/getCondition']
    })

    const state = reactive({
      modalName: '',
      condition: {
      },
    })
    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }
    const methods = {
      exportToExcel() {
        const condition = state.condition
        const params = {
        }
        Object.assign(params,condition)
        store
            .dispatch('allotoutbound/exportToExcel', params)
            .then(res => {
              toast.success("导出成功")
              let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
              let filename = "调拨单-"+new Date().getTime();
              /*-------------*/
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(data);
              link.setAttribute('download',filename+'.xlsx')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
      },
      refreshTable  (){
        emit('refreshTable')
      },
      resetCondition() {
        for (let conditionKey in state.condition) {
          state.condition[conditionKey] = undefined
        }
      },
    }
    return {
      ...toRefs(state),
      ...methods,
      fromChildren,
      //filter
      getCodeOptions,
    }
  },
}
</script>

<style scoped>

</style>
