<template>

  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0 list-table"
    >

      <div class="m-2">
        <b-card
            v-show="advanced_search_modal"
            style="width:100%"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >

            <b-row>
              <!--调拨类型        -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调拨类型:"
                    label-for="allot_type"
                    label-size="sm"
                >
                  <v-select
                      id="allot_type"
                      :options="getCodeOptions('allot_type')"
                      :clearable="true"
                      v-model = "condition.allot_type"
                      class="select-size-sm"
                      placeholder="选择调拨类型"
                  />
                </b-form-group>
              </b-col>
              <!--调拨状态        -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调拨状态:"
                    label-for="status_id"
                    label-size="sm"
                >
                  <v-select
                      id="status_id"
                      :options="getCodeOptions('allot_status')"
                      :clearable="true"
                      v-model = "condition.status"
                      class="select-size-sm"
                      placeholder="选择调拨状态"
                  />
                </b-form-group>
              </b-col>
              <!--物流审核       -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物流审核:"
                    label-for="express_check_status"
                    label-size="sm"
                >
                  <v-select
                      id="express_check_status"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model = "condition.express_check_status"
                      class="select-size-sm"
                      placeholder="选择审核状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--财务审核     -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="财务审核:"
                    label-for="is_finance"
                    label-size="sm"
                >
                  <v-select
                      id="is_finance"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model = "condition.is_finance"
                      class="select-size-sm"
                      placeholder="选择审核状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--商品名称-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.productName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--商品69码       -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="code"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.productCode"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--调拨单编号      -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调拨单编号:"
                    label-for="outbound_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.outboundNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <!--调出仓库       -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调出仓库"
                    label-for="user_level"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      v-model="condition.fromWarehouseArray"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="getCodeOptions('warehouse')"
                      class="select-size-sm"
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>
              <!--调入仓库       -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调入仓库"
                    label-for="user_level"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      v-model="condition.toWarehouseArray"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="getCodeOptions('warehouse')"
                      class="select-size-sm"
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>

              <!--调拨时间区间    -->
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button label="调拨时间"
                                 type="time"
                                 v-on:change="fromChildren($event,['begin_time'])"
                                 :params="['begin_time']"
                                 :value="condition.begin_time"
                >
                </xy-input-button>
              </b-col>

              <!--责任人         -->
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="责任人"
                    type="input"
                    v-on:change="fromChildren($event,['dutyName','dutyId'])"
                    :params="['dutyName','dutyId']"
                    :value="condition.dutyName"
                    modalName="责任人"
                    placeholder="点击选择责任人"
                >
                </xy-input-button>
              </b-col>

              <!--创建时间区间   -->
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button label="创建时间"
                                 type="time"
                                 v-on:change="fromChildren($event,['create_begin_time'])"
                                 :params="['create_begin_time']"
                                 :value="condition.create_begin_time"
                >
                </xy-input-button>
              </b-col>


              <!--业务出库时间-->
              <b-col
                  cols="12"
                  md="4"
                  style="margin-top: 15px"
              >
                <xy-input-button label="业务出库时间"
                                 type="time"
                                 v-on:change="fromChildren($event,['outbound_begin_time'])"
                                 :params="['outbound_begin_time']"
                                 :value="condition.outbound_begin_time"
                >
                </xy-input-button>
              </b-col>

              <!--业务入库时间-->
              <b-col
                  cols="12"
                  md="4"
                  style="margin-top: 15px"
              >
                <xy-input-button label="业务入库时间"
                                 type="time"
                                 v-on:change="fromChildren($event,['receipt_begin_time'])"
                                 :params="['receipt_begin_time']"
                                 :value="condition.receipt_begin_time"
                >
                </xy-input-button>
              </b-col>

              <!--查询-->
              <b-col
                  cols="12"
                  md="4"
                  style="margin-top: 15px"
              >
                <div >
                  <b-button
                      variant="outline-primary"
                      type="submit"
                      class="mr-1"
                  >
                    <span class="align-right">查询</span>
                  </b-button>

                  <b-button
                      variant="outline-primary"
                      type="reset"
                      class="mr-1"
                  >
                    <span class="align-right">重置</span>
                  </b-button>

                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="advanced_search"
                  >
                    <span class="align-right">关闭</span>
                  </b-button>


                </div>
              </b-col>
            </b-row>
          </b-form>

        </b-card>
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="3"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >

            <div class="d-flex align-items-center justify-content-end">


              <b-button
                  v-if="role.isFinanceStaff || role.isSuper"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="reload"
                  style="margin: 5px;"
              >
                <span class="align-middle">清空勾选</span>
              </b-button>


              <!--              财务审核通过-->
              <b-button
                  v-if="role.isFinanceStaff || role.isSuper"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="checkedSuccess(id=1)"
                  style="margin: 5px;"
              >
                <span class="align-middle">财务审核通过</span>
              </b-button>

              <!--              财务审核不通过-->
              <b-button v-if="role.isFinanceStaff || role.isSuper"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="checkedSuccess(id=0)"
                        style="margin: 5px;"
              >
                <span class="align-middle">财务审核不通过</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="exportToExcel()"
                  style="margin: 5px;"
              >
                <span class="align-right">导出调拨单</span>
              </b-button>
            </div>
          </b-col>
          <b-col
              cols="12"
              md="3"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
          <!-- Search -->

        </b-row>



      </div>



      <b-table
              ref="refListTable"
              class="position-relative"
              :items="warehouse"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              v-model="table"
      >

        <!-- Columns 显示隐藏详细信息 -->
        <!-- full detail on click -->
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields">
                <template #cell(ext)="data">
                  {{ data.item.ext.code }}
                </template>

                <template #cell(stock_ids)="data">
                  {{ data.item.ext.stockIds }}
                </template>

                <template #cell(name)="data">
                  [#{{ data.item.product_id }}]{{ data.item.name }}
                </template>

                <template #cell(stock_year)="data">
                  {{data.item.stock_year}}-{{data.item.stock_month}}
                </template>

                <template #cell(warestatus)="data">
                  {{ data.item.warestatus === undefined ? '-' :  getCodeLabel('stock_status', data.item.warestatus) }}
                </template>

                <template #cell(status)="data">
                  {{getCodeLabel('allot_outbound_status', data.item.status)}}
                </template>

                <template #cell(remark)="data">
                  <div :id="`remark-${data.item.id}`">
                     <span v-if="data.item.remark">
                       {{ data.item.remark.substring(0, 6) }}
                     <span v-if="data.item.remark.length>6">...</span>
                     </span>
                  </div>
                  <b-tooltip :target="`remark-${data.item.id}`" placement="top">
                    {{ data.item.remark }}
                  </b-tooltip>
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <!-- Columns -->
        <template v-slot:head(select)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>

        <template v-slot:cell(select)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>


        <!-- Column: Type -->
        <template #cell(outbound_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]{{ data.item.outbound_no }}
        </template>

        <template #cell(allot_type)="data">
          {{ getCodeLabel('allot_type', data.item.allot_type) }}
        </template>

        <template #cell(duty_name)="data">
          {{ data.item.duty_name }}
        </template>

        <template #cell(fromwarehouse_name)="data">

          {{ data.item.fromwarehouse_name }}
          <feather-icon
              icon="UserIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="showWarehouseCharge(data.item)"
              variant="success"
          />
          <!--          <br>-->
          <!--          {{data.item.from_contact}}  {{data.item.from_mobile}}-->
          <!--          <br>-->
          <!--          {{data.item.from_location}}-->
        </template>

        <template #cell(towarehouse_name)="data">
          {{ data.item.towarehouse_name }}
          <feather-icon
              icon="UserIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="showToWarehouseCharge(data.item)"
              variant="success"
          />
          <!--          <br>-->
          <!--          {{data.item.to_contact}}  {{data.item.to_mobile}}-->
          <!--          <br>-->
          <!--          {{data.item.to_location}}-->
        </template>

        <template #cell(allot_time)="data">
          {{ toTime(data.item.allot_time) }}
        </template>

        <template #cell(status)="data">
          <b-badge
                  pill
                  :variant="`light-${getCodeColor('allot_status', data.item.status)}`"
          >
            {{ getCodeLabel('allot_status', data.item.status) }}
          </b-badge>
        </template>

        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(memo)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'allot_outbound'"
                             :object_id="0"
                             :readonly="true"
                             @change="onUploaded"
          />
          <!-- 备注 -->
          <div :id="`memo-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
                  :target="`memo-row-${data.item.id}`"
                  placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(outbound_creator)="data">
          {{getCodeLabel('user', data.item.outbound_creator)}}
        </template>


        <template #cell(outbound_time)="data">
          {{toTime(data.item.outbound_time)}}
        </template>



        <template #cell(memo_wh)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_wh'"
                             :id="data.item.attachments_wh"
                             :object_type="'allot_outbound'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
          <!-- 备注 -->
          <div :id="`memo_wh-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
                  :target="`memo_wh-row-${data.item.id}`"
                  placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(memo_ch)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_ch'"
                             :id="data.item.attachments_ch"
                             :object_type="'allot_outbound'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
          <!-- 备注 -->
          <div :id="`memo_ch-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo_ch-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>



        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
                  @click="changeState(data.item)"
          >
            <b-badge
                    pill
                    :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>


        <template #cell(express_check_status)="data">
          <b-link
                  @click="changeExpressStatus(data.item)"
          >
            <b-badge
                    pill
                    :variant="`light-${getCodeColor('yesno',data.item.express_check_status==1?1:0)}`"
            >
              {{ getCodeLabel('yesno', data.item.express_check_status==1?1:0) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(is_finance)="data">
          <b-link
              @click="changeIsFinanceStatus(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('yesno',data.item.is_finance==1?1:0)}`"
            >
              {{ getCodeLabel('yesno', data.item.is_finance==1?1:0) }}
            </b-badge>
          </b-link>
        </template>


<!--test-->
        <template #cell(business_time_allotbound)="data">
          {{ toTime(data.value) }}
          <feather-icon  icon="EditIcon" @click="showBusinessTime(data.item)" v-if="role.isFinanceStaff || role.isSuper || data.item.is_finance === 0"/>
        </template>


        <template #cell(business_time_receipt)="data">
          {{ toTime(data.value) }}
          <feather-icon  icon="EditIcon" @click="showBusinessTimeReceipt(data.item)" v-if="role.isFinanceStaff || role.isSuper || data.item.is_finance === 0"/>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data"  >
          <b-dropdown
                  variant="link"
                  no-caret
                  right
          >

            <template #button-content>
              <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
              />
            </template>

            <div v-if="data.item.status === 3 && ! isWarehouseStaff">
              <b-dropdown-item  @click="rollback(data.item)">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">申请回滚</span>
              </b-dropdown-item>
              <b-dropdown-item
                  :to="{ name: 'apps-stockrollback-list', query: { order_type:2,relational_id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">查看库存回滚审批</span>
              </b-dropdown-item>
            </div>


<!--            <b-dropdown-item  @click="confirmOutBound(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">调入仓回执</span>
            </b-dropdown-item>-->


            <b-dropdown-item @click="toLODOP(data.item.outbound_id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">打印</span>
            </b-dropdown-item>


            <b-dropdown-item @click="detailOutBound(data.item.outbound_id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">出库明细</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showFileAndMemoWindow(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">入库附件</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showFileAndMemoWindowCh(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">出库附件</span>
            </b-dropdown-item>

<!--     跳转页面       -->
            <b-dropdown-item @click="toOutboudOutbound(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">调拨出库单</span>
            </b-dropdown-item>

            <b-dropdown-item @click="toOutboudInbound(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">调拨入库单</span>
            </b-dropdown-item>

            <b-dropdown-item v-show="data.item.status === 1" @click="rejectEvent(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <br>
    <b-modal
        id="modal-rollback"
        centered
        size="lg"
        hide-footer
        title="库存回滚申请"
        ref="rollbackModal"
    >
      <stock-rollback-edit :relation="curRow" :orderType="2"  @closeModal="closeModalRollback"
      ></stock-rollback-edit>
    </b-modal>

    <b-card header="出库/回执 操作" v-show="outboundid > 0 && type == 1">
      <warehouse-list
              :outbound_id="outboundid" v-if="whReset"
      >
      </warehouse-list>
    </b-card>

    <b-card header="出库明细" v-show="outboundid > 0 && type == 2">
      <StockAllotOutboundItemList
              :outbound_id="outboundid" v-if="detailReset"
      >
      </StockAllotOutboundItemList>
    </b-card>


    <!-- 添加入库附件窗口 -->
    <b-modal
            id="fileModal"
            ok-only
            ok-title="确认"
            cancel-title="取消"
            centered
            size="sm"
            title="添加附件"
            ref="fileModal"
            @ok="submit"
            @hidden="clearInput"
    >
      <div>
        <b-col cols="12">
          <label>备注</label>
          <b-form-textarea v-model="contentInfo"></b-form-textarea>
        </b-col>
        <br>
        <b-col cols="12">
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments_ids"
                  label-size="sm"
                  class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :attachment_id="'attachments_wh'"
                               :id="memoFile"
                               :object_type="'allot_outbound'"
                               :object_id="attachmentsObjectId"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </div>
      <br>
    </b-modal>

<!-- 添加出库附件窗口  -->
    <b-modal
        id="fileModalCh"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="sm"
        title="添加附件"
        ref="fileModalCh"
        @ok="submitCh"
        @hidden="clearInput"
    >
      <div>
        <b-col cols="12">
          <label>备注</label>
          <b-form-textarea v-model="contentInfoCh"></b-form-textarea>
        </b-col>
        <br>
        <b-col cols="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件"
              label-for="attachments_ids"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :attachment_id="'attachments_ch'"
                               :id="memoFileCh"
                               :object_type="'allot_outbound'"
                               :object_id="attachmentsObjectIdCh"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </div>
      <br>
    </b-modal>

    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <!--  <company-select
                ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
        >
        </company-select>-->
      <WareHouse-Select
          ref="whSelect" v-if="modalName === '调出仓库' || modalName === '调入仓库' "
      >
      </WareHouse-Select>

      <!--:可加入 判断所属部门 userDepartment="userDepartment"-->
      <user-select
          ref="userSelect"  v-else-if="modalName === '责任人' "
      >
      </user-select>

    </b-modal>


    <b-modal
        id="businessTimeModal"
        size="lg"
        centered
        title="修改业务出库时间"
        ref="businessTimeModal"
        hide-footer
    >
      <allot-time :id="outboundid"  @hide="hideBusinessTime"></allot-time>
    </b-modal>

    <b-modal
        id="businessTimeModalReceipt"
        size="lg"
        centered
        title="修改业务入库时间"
        ref="businessTimeModalReceipt"
        hide-footer
    >
      <allot-time-receipt :id="outboundid"  @hide="hideBusinessTimeReceipt"></allot-time-receipt>
    </b-modal>


    <b-modal
        id="pdfModal"
        centered
        title="PDF"
        ref="pdfModal"
        hide-footer
    >
      <attachment-upload theme="preview"
                         attachment_id="attachments"
                         :id="pdf_id"
                         :readonly="true"
                         v-if="pdf_id"
      />
      <div v-else>
        <p class="my-4">正在生成，请稍候!</p>
      </div>

    </b-modal>


    <b-modal
        id="modal-warehouse-charge"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="仓库负责人"
        ref="modal-warehouse-charge"
    >
      <warehouse-charge-list :warehouseId="itemWarehouseId" :warehouseName="itemWarehouseName" >

      </warehouse-charge-list>

    </b-modal>
  </div>
</template>

<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import {ref, onUnmounted, reactive, toRefs, onMounted} from '@vue/composition-api'
  import {
    avatarText,
    toDate,
    getCodeOptions,
    getCode,
    getCodeLabel,
    getCodeColor,
    second,
    lodopWatermark, isEmpty
  } from '@core/utils/filter'
  import { toTime, toMonth } from '@core/utils/dayjs/utils'
  import allotOutboundSearchUseList from './allotoutboundSearchUseList'
  import allotoutboundStore from './allotoutboundStore'
  import WarehouseList from '@/views/apps/allotoutbounditem/WareHouse'
  import StockAllotOutboundItemList from '@/views/apps/stockallotoutbounditem/StockAllotOutboundItemList'
  import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
  import {useRouter} from "@core/utils/utils";
  import {useToast} from "vue-toastification/composition";
  import Spinner from "@/views/components/spinner/Spinner";
  import Ripple from "vue-ripple-directive";
  import UserSelect from '@/views/apps/user/user-modal/UserSelect'
  import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
  import StockRollbackEdit from '@/views/apps/stockrollback/StockRollbackEdit'
  import {getUserData} from "@/auth/utils";
  import AllotTime from "@/views/apps/allotoutbound/components/allotTime";
  import AllotTimeReceipt from "@/views/apps/allotoutbound/components/allotTimeReceipt";
  import WarehouseChargeList from "@/views/apps/warehousecharge/warehousecharge-modal/WarehouseChargeList";
  import Search from '@/views/apps/allotoutbound/allotOutboundSearch'
  import allotOutboundSearch from "@/views/apps/allotoutbound/allotOutboundSearch";
  import stockrollbackStore from "@/views/apps/stockrollback/stockrollbackStore";
  import XyInputButton from "@/views/components/xy/XyInputButton";
  import {getLodop} from "@/libs/LodopFuncs";
  import lodopStore from "@/views/apps/lodop/lodopStore";


  export default {
    components: {
      WarehouseChargeList,
      AllotTimeReceipt,
      AllotTime,
      StockRollbackEdit,
      Spinner,
      WarehouseList,
      StockAllotOutboundItemList,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      AttachmentUpload,
      UserSelect,
      WareHouseSelect,
      Search,
      XyInputButton
    },
    directives: {
      Ripple,
    },
    methods: {

      changeState: function (data) {
        console.log("------------------yang")
        const state = 1 - data.state
        store.dispatch('allotoutbound/state', {
          id: data.id,
          state: state
        })
                .then(res => {
                  data.state = state
                })
      },




      detailOutBound: function (id) {
        this.outboundid = id
        this.type = 2
        this.detailReset = false

        this.$nextTick(() => {
          this.detailReset = true
        })
      },

      showFileAndMemoWindow: function (params) {
        this.contentInfo = params.memo_wh
        this.memoFile  =params.attachments_wh
        this.attachmentId = params.outbound_id
        this.attachmentsObjectId = params.id
        this.uploadInfo=''
        this.$refs['fileModal'].show()
      },

      showFileAndMemoWindowCh: function (params) {
        this.contentInfoCh = params.memo_ch
        this.memoFileCh  =params.attachments_ch
        this.attachmentId = params.outbound_id
        this.attachmentsObjectIdCh = params.id
        this.uploadInfo=''
        this.$refs['fileModalCh'].show()
      },
      clearInput: function() {
        this.uploadInfo =''
        this.contentInfo = ''
        this.contentInfoCh = ''
        this.$refs['refListTable'].refresh()
      },

      showSingleModal(modalName) {
        this.modalName = modalName
        this.$refs['singleModal'].show()
      },

      onSelectSingle() {
        let data = {}
        switch (this.modalName) {
          case '责任人': {
            data = this.$refs.userSelect.getSelected()
            if(data.length > 0){
              this.dutyName = data[0].full_name;
              this.dutyId = data[0].user_id;
            }

            break
          }
          case '调出仓库': {
            data = this.$refs.whSelect.getSelected()
            if(data.length > 0){
              this.fromwarehousename = data[0].warehouse_name;
              this.fromwarehouseId = data[0].warehouse_id;
            }
            break
          }
          case '调入仓库':{
            data = this.$refs.whSelect.getSelected()
            if(data.length > 0){
              this.towarehousename = data[0].warehouse_name;
              this.towarehouseId = data[0].warehouse_id;
            }
            break;
          }
          default: {
            //statements;
            break
          }
        }
      },

      toggleAll(checked) {
        console.log("进入全选")
        this.checked = []
        this.table.forEach(element => {
          if (checked) {
            this.checked.push(element);
          }
          this.isCheckedObj['index' + element.id] = checked;
        });
        this.$forceUpdate()
      },
      // 复选框点击选中与取消
      checkboxClick(id) {
        if(this.checked==undefined){
          this.checked=[]
        }

        let index = this.checked.indexOf(id);
        if (index > -1) {
          this.checked.splice(index, 1);
        } else {
          this.checked.push(id);
        }
        this.$forceUpdate()
      },
    },
    data() {
      return {
        allchecked:false,
        business_time: '',
        outboundid: 0,
        whReset: true,
        detailReset: true,
        type: 0,
        attachmentsObjectId:0,
        attachmentsObjectIdCh:0,
        attachments_ids: '',
        contract: '',
        indeterminate: false,
        memoFile: "",
        memoFileCh: "",
        uploadInfo: '',
        isCheckedObj: {},
        table: [],
       // checked: [],
        attachmentId: null,
        contentInfo: '',
        contentInfoCh: '',
        fields: [
          {key: 'ext', label: '商品69码'},
          {key: 'name', label: '名称'},
          {key: 'stock_year', label: '生产日期'},
          {key: 'warestatus', label: '库存状态'},
          {key: 'qty', label: '计划调拨数量'},
          { key: 'remark', label: '备注'},
          {key: 'trueqty', label: '实际调拨库数量'},
          {key: 'status', label: '出库状态'},
        ],
        advanced_search_modal: false,
        modalName: '',
        fromwarehousename:'',
        towarehousename:'',
        dutyName: '',
        show:JSON.parse(localStorage.getItem('userData')).role_id==17||JSON.parse(localStorage.getItem('userData')).role_id==18,
        itemWarehouseId:0,
        itemWarehouseName:"",
      }
    },
    mounted() {
      this.isCheckedObj = {};
      this.table.forEach(element => {
        this.$set(this.isCheckedObj, 'index' + element.id, false)
      });
    },
    watch: {
      checked: {
        handler(newVal, oldVal) {
          if (newVal.length == 0) {
            this.indeterminate = false
            this.allchecked = false
          } else if (newVal.length == this.table.length) {
            this.indeterminate = false
            this.allchecked = true
          } else {
            this.indeterminate = true
            this.allchecked = false
          }
          this.$forceUpdate()
        },
        deep: true//深度
      }
    },
    setup() {
      const toast = useToast()
      // Register module
      if (!store.hasModule('allotoutbound')) store.registerModule('allotoutbound', allotoutboundStore)
      if (!store.hasModule('stockrollback')) store.registerModule('stockrollback', stockrollbackStore)
      if (!store.hasModule('lodop')) store.registerModule('lodop', lodopStore)
      const router = useRouter()


      onMounted(() => {
        state.condition = store.getters['allotoutbound/getCondition']
      })


      const state = reactive({
        condition:{
        },
        curRow:undefined,
        rollbackModal:undefined,
        pdf_id:undefined,
        pdfModal:undefined,
      })

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule('allotoutbound')) store.unregisterModule('allotoutbound')
        if (store.hasModule('stockrollback')) store.unregisterModule('stockrollback')
        if (store.hasModule('lodop')) store.unregisterModule('lodop')
       // state.condition = store.getters['allotoutbound/getCondition']
      })



      const onUploaded = function (id, attachment, result) {
        this.uploadInfo = result
      }

      const submit = function () {
        let fileArr = []

        store.dispatch('allotoutbound/updateWhInfo', {
          'outboundId': this.attachmentId,
          'attachmentsWh': this.uploadInfo,
          'memoWh': this.contentInfo,
        }).then(res => {
          if (res.status === 200) {
            toast.success('提交成功')
            this.refetchData()
          }
        })
      }

      const submitCh = function () {
        let fileArr = []

        store.dispatch('allotoutbound/updateChInfo', {
          'outboundId': this.attachmentId,
          'attachmentsWh': this.uploadInfo,
          'memoWh': this.contentInfoCh,
        }).then(res => {
          if (res.status === 200) {
            toast.success('提交成功')
            this.refetchData()
          }
        })
      }

      const rejectEvent = function(item) {
        store.dispatch('allotoutbound/rejectAllotOutboundData', item)
         .then(res => {
           if (res.data.code === 0) {
             toast.success("驳回成功")
             this.refetchData()
           }else {
             toast.error(res.data.data)
           }
         })
      }

      const changeExpressStatus= function (data) {
        if (data.express_check_status === 1){
          toast.error("已经审批，请勿重复点击")
          return false
        }
        store.dispatch('allotoutbound/changeExpressStatus', {
          outbound_id: data.id,
          express_check_status: 1
        })
                .then(res => {
                  if (res.data.code === 0){
                    toast.success("审批通过")
                    refetchData()
                  }else {
                    toast.error(res.data.data)
                  }
                })
      }

      const changeIsFinanceStatus= function (data) {
        let  result = 0
        if (data.is_finance === 1){
          result = 0;
        }else{
          result = 1 ;
        }
        store.dispatch('allotoutbound/changeIsFinanceStatus', {
          outbound_id: data.id,
          is_finance: result
        })
            .then(res => {
              if (res.data.code === 0){
                toast.success("修改成功")
                refetchData()
              }else {
                toast.error(res.data.data)
              }
            })
      }

      const advanced_search = function () {
        this.advanced_search_modal = this.advanced_search_modal ? false : true;
      }



      const methods = {
        rollback(row){
          store.dispatch('stockrollback/isCanRollback', { id: row.outbound_id,type:'allot_outbound' })
              .then(res => {
                if (res.data.code === 0) {
                  state.curRow = row
                  state.rollbackModal.show()
                } else {
                  toast.error(res.data.data)
                }
              })
          /*state.curRow = row
          state.rollbackModal.show()*/
        },
        closeModalRollback(){
          state.rollbackModal.hide()
        },
        searchByCondition  (){
          this.refetchData()
        },
        resetCondition() {
          for (let conditionKey in state.condition) {
            state.condition[conditionKey] = undefined
          }
          this.refetchData()
        }
      }

      const { role_id } = getUserData()
      const role = {
        isWarehouseStaff:role_id === 9 || role_id === 10 || role_id === 21,
        isExpressStaff:role_id === 17 || role_id === 18,
        isFinanceStaff:role_id === 11 || role_id === 12,
        isSuper:role_id === 14
      }

      const showBusinessTime=function (item){
        this.outboundid = item.outbound_id
        this.$refs['businessTimeModal'].show()
      }

      const  hideBusinessTime=function (){
        this.$refs['businessTimeModal'].hide()
        refetchData()
      }


      const showBusinessTimeReceipt=function (item){
        this.outboundid = item.outbound_id
        this.$refs['businessTimeModalReceipt'].show()
      }



      const hideBusinessTimeReceipt=function (){
        this.$refs['businessTimeModalReceipt'].hide()
        refetchData()
      }

      const toLODOP = function (params) {
        store.dispatch('lodop/createAllotOutboundLODOP', {
          id: params,
        }).then(res => {
          if (res.data.code === 0) {
            const data = res.data.data
            var LODOP = getLodop();
            if (LODOP == undefined) {
              toast.error("打印插件未安装，请安装插件!")
              return;
            }
            LODOP.PRINT_INIT('打印出库单') //打印初始化
            //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
            LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
            // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
            LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
            // LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
            LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
            var html = ''
            var item = ``
            var items = data.ext.item
            for (let j = 0; j < items.length; j++) {
              item += ` <tr height="23px">
                        <td align="center">${items[j].ext.no}</td>
                        <td align="center">${items[j].ext.code}</td>
                        <td align="left">${(items[j].name.length>28)?items[j].name.substring(0,28):items[j].name}</td>
                        <td align="center">${items[j].ext.specification}</td>
                        <td align="center">${items[j].ext.productUnit}</td>
                        <td align="center">${items[j].qty===undefined?0:items[j].qty}</td>
                        <td align="center">${items[j].trueqty===undefined?0:items[j].trueqty}</td>
                        <td align="center">${items[j].ext.memo}</td>
                    </tr>`
            }
            html = `
                <div style="width:780px">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="30px">
                        <td colspan="2">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="30px">
                                    <td width="50%">${data.ext.time}(第${data.print_times}次)</td>
                                    <td width="20%" align="center"><font face="楷体" size="4">出库单</font></td>
                                    <td width="30%" align="right"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="33%">收货单位：${data.towarehouse_name}</td>
                        <td width="33%">类型：${getCodeLabel('allot_type', data.allot_type)}</td>
                        <td width="33%">单据编号：${data.outbound_no}</td>
                    </tr>
                    <tr height="20px">
                        <td width="33%">发货仓库：${data.fromwarehouse_name}</td>
                        <td width="33%">计划日期：${data.allot_time==undefined?'':toDate(data.allot_time)}</td>
                        <td width="33%">出库时间：${data.outbound_time==undefined?'':toTime(data.outbound_time)}</td>
                    </tr>
                </table>
                <table width="100%" class="protable">
                    <tr height="25px">
                        <td width="4%" align="center">序号</td>
                        <td width="12%" align="center">69码</td>
                        <td align="center">商品名称</td>
                        <td width="8%" align="center">规格</td>
                        <td width="5%" align="center">单位</td>
                        <td width="6%" align="center">计划数量</td>
                        <td width="7%" align="center">本次数量</td>
                        <td width="18%" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="center">合计</td>
                        <td align="center"></td>
                        <td align="center"></td>
                        <td align="center"></td>
                        <td align="center">${data.ext.allQty}</td>
                        <td align="center">${data.ext.allTrueQty}</td>
                        <td align="center"></td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr height="20px">
                        <td width="100%">通知备注：${data.memo==undefined?'':data.memo}</td>
                </tr>
                </table>

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr height="25px">
                                <td width="34%">制单人：${data.ext.user}</td>
                                <td width="33%">仓库备注：${data.memo_ch==undefined?'':data.memo_ch}</td>
                                <td width="33%"></td>
                              </tr>
                          </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="25%">车型：${data.ext.aoo==undefined?'':data.ext.aoo.car_detail==undefined?'':getCodeLabel('car_detail',data.ext.aoo.car_detail)}</td>
                        <td width="25%">车牌号码：${data.ext.aoo==undefined?'':data.ext.aoo.car_no==undefined?'':data.ext.aoo.car_no}</td>
                        <td width="25%">联系电话：${data.ext.aoo==undefined?'':data.ext.aoo.car_phone==undefined?'':data.ext.aoo.car_phone}</td>
                        <td width="25%">司机签字：${data.ext.aoo==undefined?'':data.ext.aoo.car_driver==undefined?'':data.ext.aoo.car_driver}</td>
                    </tr>
                    <tr height="50px">
                        <td>发货人签字：</td>
                        <td>审核人签字：</td>
                        <td>收货人签字：</td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td><font size="2px">**白联：存根|红联：客户|蓝联：财务|绿联：司机|黄联：仓管</font> **<font size="2px" style="font-weight: bold">友情提醒：货物请当面点清，事后恕不负责**</font></td>
                    </tr>
                </table>
                </div>
          `
            var style =
                "<style>" +
                "table{}" +
                "table td{font-size: 14px;white-space: nowrap;}" +
                ".protable{border-collapse: collapse;border: none;}" +
                ".protable td{border: solid #000 1px;}" +
                "</style>";
            lodopWatermark(LODOP)
            LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, style + '<body size="10px">' + html + '</body>') // 增加超文本项（打印内容即为body内容）
            LODOP.NewPage();
            LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
            this.refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }

      const reload = function () {
        this.checked.forEach(element => {
          this.isCheckedObj['index' + element.id] = false;
        });
        this.checked.length = 0
        this.indeterminate = false
        this.allchecked = false
        refetchData()
      }


      const checkedSuccess = function (id){


          store.dispatch('allotoutbound/changeIsFinanceStatusAll', {
            outbound_id_arr: this.isCheckedObj,
            is_finance: id,
          })
              .then(res => {
                if (res.data.code === 0){
                  toast.success("修改成功")
                  refetchData()
                }else {
                  toast.error(res.data.data)
                }
              })

      }

      const showWarehouseCharge = function (params) {
        this.itemWarehouseId = params.fromwarehouse_id
        this.itemWarehouseName = params.fromwarehouse_name
        this.$refs['modal-warehouse-charge'].show()
      }

      const showToWarehouseCharge = function (params) {
        this.itemWarehouseId = params.towarehouse_id
        this.itemWarehouseName = params.towarehouse_name
        this.$refs['modal-warehouse-charge'].show()
      }

      const  confirmOutBound = function (params) {
        this.outboundid = params.outbound_id
        state.condition.outboundNo=params.outbound_no
        this.type = 1
        this.$router.push({name: 'apps-allotoutbounditem-warehouse', query: {outbound_id: this.outboundid,towarehouse_id:params.towarehouse_id}});
        this.whReset = false
        this.$nextTick(() => {
          this.whReset = true
        })

        /* let routerData = this.$router.resolve({name: 'apps-allotoutbounditem-warehouse', query: {outbound_id:params.outbound_id,towarehouse_id:params.towarehouse_id}});
         window.open(routerData.href, '_blank')*/
      }

      const  toOutboudOutbound = function (params) {
        this.outboundid = params.outbound_id
        state.condition.outboundNo=params.outbound_no
        this.$router.push({name: 'apps-allotoutboundoutbound-list', query: {id: this.outboundid}});
      }

      const  toOutboudInbound = function (params) {
        this.outboundid = params.outbound_id
        state.condition.outboundNo=params.outbound_no
        this.$router.push({name: 'apps-allotoutboundinbound-list', query: {id: this.outboundid}});
      }

      const   exportToExcel = function () {
        const condition = state.condition
        console.log(condition)
        let time =  condition.outbound_begin_time;
        let receipt_begin_time =  condition.receipt_begin_time;
        let create_begin_time =  condition.create_begin_time;
        let begin_time =  condition.begin_time;
        // if(time==""&&receipt_begin_time==""&&create_begin_time==""&&begin_time==""){
        //   toast.error("导出调拨单需要选择时间限制,请在高级搜索中选择调拨/创建/业务出入库其中一个区间时间限制。");
        //   return;
        // }
        // if(time!=""){
        //  let subDays =  getDays(time);
        //  console.log(subDays)
        //  if(subDays>400){
        //    toast.error("时间区间过大,目前只允许导出400天范围内的数据。")
        //    return;
        //  }
        // }
        // if(receipt_begin_time!=""){
        //   let subDays =  getDays(receipt_begin_time);
        //   console.log(subDays)
        //   if(subDays>400){
        //     toast.error("时间区间过大,目前只允许导出400天范围内的数据。")
        //     return;
        //   }
        // }
        // if(create_begin_time!=""){
        //   let subDays =  getDays(create_begin_time);
        //   console.log(subDays)
        //   if(subDays>400){
        //     toast.error("时间区间过大,目前只允许导出400天范围内的数据。")
        //     return;
        //   }
        // }
        // if(begin_time!=""){
        //   let subDays =  getDays(begin_time);
        //   console.log(subDays)
        //   if(subDays>400){
        //     toast.error("时间区间过大,目前只允许导出400天范围内的数据。")
        //     return;
        //   }
        // }
        const params = {
        }
        Object.assign(params,condition)
        const fromWarehouseArray = []
        if (!isEmpty(condition.fromWarehouseArray)){
          condition.fromWarehouseArray.forEach((value, index, array) => {
            fromWarehouseArray.push(value.value)
          })
        }
        params.fromWarehouseIds = fromWarehouseArray.join(",")
        const toWarehouseArray = []
        if (!isEmpty(condition.toWarehouseArray)){
          condition.toWarehouseArray.forEach((value, index, array) => {
            toWarehouseArray.push(value.value)
          })
        }
        params.toWarehouseIds = toWarehouseArray.join(",")
        store
            .dispatch('allotoutbound/exportToExcel', params)
            .then(res => {
              toast.success("导出成功")
              let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
              let filename = "调拨单-"+new Date().getTime();
              /*-------------*/
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(data);
              link.setAttribute('download',filename+'.xlsx')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
      }

      const fromChildren = function (params, modal) {
        for (let i = 0; i < modal.length; i++) {
          state.condition[modal[i]] =params==null?null:params[modal[i]]
        }
        this.$forceUpdate()
      }

      const getDays = function (time) {
              let timeSeparator= "至";
              let strTime = time.split(timeSeparator);
              let strStart = strTime[0].trim();
              let endStart = strTime[1].trim();
              let strSeparator = "-";
        let oDate1;
        let oDate2;
        let iDays;
        oDate1= strStart.split(strSeparator);
        oDate2= endStart.split(strSeparator);
        let strDateS = new Date(oDate1[0], oDate1[1]-1, oDate1[2]);
        let strDateE = new Date(oDate2[0], oDate2[1]-1, oDate2[2]);
        iDays = parseInt(Math.abs(strDateS - strDateE ) / 1000 / 60 / 60 /24);
        return iDays ;
      }

      const {
        warehouse,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        // 高级搜索
        allottype,
        towarehouseId,
        fromwarehouseId,
        productCode,
        productName,
        create_begin_time,
        begin_time,
        create_end_time,
        end_time,
        dutyId,
        statusId,
        express_check_status,
        is_finance,
        outboundNo,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        outbound_begin_time,
        outbound_end_time,
        receipt_begin_time,
        receipt_end_time,

        // UI
      } = allotOutboundSearchUseList({
        outbound_id:router.route.value.query.outbound_id,
        isStockLog:router.route.value.query.isStockLog,
        role:role
      })

      return {
        ...toRefs(state),
        ...methods,
        toLODOP,

        warehouse,
        tableColumns,
        limit,
        start,
        state,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        // 高级搜索条件
        allottype,
        towarehouseId,
        fromwarehouseId,
        productCode,
        productName,
        begin_time,
        end_time,
        dutyId,
        statusId,
        express_check_status,
        is_finance,
        outboundNo,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        create_begin_time,
        create_end_time,
        outbound_begin_time,
        outbound_end_time,
        receipt_begin_time,
        receipt_end_time,
        showBusinessTime,
        hideBusinessTime,
        hideBusinessTimeReceipt,
        showBusinessTimeReceipt,
        // Filter
        avatarText,
        getCodeOptions,
        getCode,
        getCodeLabel,
        getCodeColor,
        showWarehouseCharge,
        showToWarehouseCharge,
        // UI
        toTime,
        onUploaded,
        submit,
        rejectEvent,
        advanced_search,
        changeExpressStatus,
        changeIsFinanceStatus,
        reload,
        ...role,
        role,
        checkedSuccess,
        isWarehouseStaff:store.state['allotoutbound'].isWarehouseStaff,
        submitCh,
        confirmOutBound,
        toOutboudOutbound,
        toOutboudInbound,
        exportToExcel,
        fromChildren,
        getDays
      }
    },
   /* updated() {
      var url = window.location.href;
      console.log(url)
      this.$route.fullPath=this.$route.path
    }*/

  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
