<template>
  <div>
      <ValidationObserver ref="observer" >
        <b-row>
          <b-col md="12">
            <xy-date-picker label-cols="2" label="业务时间" :val.sync="business_time"
                            rules="required"
                            :config="{enableTime: true,maxDate: 'today'}" id="business_time"
            >
            </xy-date-picker>
          </b-col>
          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="save"
            >
              保存
            </b-button>
          </b-col>
        </b-row>
      </ValidationObserver>
  </div>
</template>

<script>
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import purchaseinboundStore from '@/views/apps/purchaseinbound/purchaseinboundStore'
import { useRouter } from '@core/utils/utils'
import { onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { second } from '@core/utils/dayjs/utils'
import allotoutboundStore from "@/views/apps/allotoutbound/allotoutboundStore";

export default {
  components: { XyDatePicker },
  props: ['id'],
  setup(props,{emit}) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('allotoutbound')) store.registerModule('allotoutbound', allotoutboundStore)

    const state = reactive({
      business_time: undefined,
      observer: undefined,
    })

    const methods = {
      async save() {
        const validate = await state.observer.validate()
        if (!validate) {
          toast.error('请正确填写必填字段！')
          return
        }
        store.dispatch('allotoutbound/updateModel', {
          inbound_id: props.id,
          business_time: state.business_time
        })
            .then(res => {
              if (res.data.code === 0) {
                toast.success(res.data.data)
                emit('hide')
              } else {
                toast.error(res.data.data)
              }
            })
      }
    }
    return {
      ...toRefs(state),
      ...methods,
    }
  }
}
</script>

<style scoped>

</style>
